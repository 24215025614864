<template>
  <img alt="Vue logo" class="top-img" src="./assets/images/top.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
body,html {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
	width: 0px;
}
.top-img {
  width: 100%;
  height: 100%;
}

@media (min-width: 1881px) {
  html {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 1721px) and (max-width: 1880px) {
  html {
    font-size: 9.15px !important;
  }
}

@media only screen and (min-width: 1521px) and (max-width: 1720px) {
  html {
    font-size: 8px !important;
  }
}

@media only screen and (min-width: 1321px) and (max-width: 1520px) {
  html {
    font-size: 7.1px !important;
  }
}

@media only screen and (min-width: 1161px) and (max-width: 1320px) {
  html {
    font-size: 6.35px !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1160px) {
  html {
    font-size: 5.55px !important;
  }
}

@media only screen and (min-width: 891px) and (max-width: 1024px) {
  html {
    font-size: 5px !important;
  }
}

@media only screen and (min-width: 751px) and (max-width: 890px) {
  html {
    font-size: 4.5px !important;
  }
}

@media (max-width: 750px) {
  html {
    font-size: 4px !important;
  }
}
</style>
